<template>
  <div class="forget-container">
    <div class="top d-f a-c">
      <div class="img-con">
        <img src="@/assets/image/login_logo.png" alt="" />
      </div>
    </div>
    <div class="space">
      <div class="login-box">
        <a-menu :selected-keys="[current]" mode="horizontal" @click="changeLoginWay">
          <a-menu-item key="confirm">1.确认账号</a-menu-item>
          <a-menu-item key="reset" :disabled="cannotReset">2.重置密码</a-menu-item>
        </a-menu>
        <confirm v-if="current === 'confirm'"></confirm>
        <reset v-if="current === 'reset'"></reset>
      </div>
    </div>
  </div>
</template>

<script>
import Confirm from "./confirm.vue";
import Reset from "./reset.vue";
export default {
  data() {
    return {
      current: "confirm",
      cannotReset: true,
    };
  },
  components: {
    Confirm,
    Reset,
  },
  methods: {
    //登录方式改变
    changeLoginWay(e) {
      this.current = e.key;
      if (this.current === "confirm") {
        this.cannotReset = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.forget-container {
  width: 1440px;
  height: 100vh;
  background: url("~@/assets/image/login_bac.png") no-repeat center/100% 100%;
  overflow: hidden;
  .top {
    width: 1440px;
    height: 72px;
    padding-left: 120px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.6);
    .img-con {
      width: 177px;
      height: 42px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .space {
    width: 1440px;
    height: calc(~"100% - 72px");
    position: relative;
    .login-box {
      width: 430px;
      height: 500px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(52, 38, 0, 0.1);
      opacity: 1;
      border-radius: 16px;
      position: absolute;
      right: 230px;
      top: 72px;
      padding: 60px 50px 0;
      box-sizing: border-box;
    }
    .ant-menu-horizontal {
      border-bottom: none;
    }
    .ant-menu {
      display: flex;
      justify-content: space-around;
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div>
    <a-form-model
      ref="ruleResetForm"
      class="form-model"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item prop="newPassword">
        <a-input
          v-model="form.newPassword"
          placeholder="请输入密码"
          size="large"
          type="password"
          autocomplete="off"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="confirmNewPassword">
        <a-input
          v-model="form.confirmNewPassword"
          placeholder="请再次输入密码"
          size="large"
          type="password"
          autocomplete="off"
        >
          <a-icon
            slot="prefix"
            type="safety-certificate"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="complete" class="submit">完成</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      checkAgree: false,
      form: {
        newPassword: "",
        confirmNewPassword: "",
      },
      rules: {
        newPassword: [
          { required: true, message: "请输入你的密码!" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含数字和英文，长度6-20",
          },
        ],
        confirmNewPassword: [
          { required: true, message: "请确认你的密码!" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含数字和英文，长度6-20",
          },
        ],
      },
    };
  },
  methods: {
    //完成
    complete() {
      this.$refs.ruleResetForm.validate((valid) => {
        if (valid) {
          let params = {
            phone: this.$store.state.forget.phone,
            newPassword: this.form.newPassword,
            confirmNewPassword: this.form.confirmNewPassword,
          };
          console.log(params);
          //   this.$api.updatePasswordByPhone(params).then((res) => {
          //     if (res.success) {
          //       //路由跳转
          //       this.$message.success("修改成功，请重新登录");
          //       this.$router.push("login");
          //     } else {
          //       this.$message.error(res.message);
          //     }
          //   });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.form-model {
  margin-top: 40px;
  width: 100%;
  ::v-deep .ant-input-affix-wrapper {
    .ant-input {
      padding-left: 40px;
      border: none;
      border-bottom: 1px solid #e8eaee;
    }
  }
  .submit {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
}
</style>

<template>
  <div>
    <a-form-model
      ref="ruleConfirmForm"
      class="form-model"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item prop="phone">
        <a-input v-model="form.phone" placeholder="手机号" size="large">
          <a-icon slot="prefix" type="mobile" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item
        prop="smsCode"
        :wrapper-col="{ span: 14, offset: 0 }"
        class="send-smsCode"
      >
        <a-input v-model="form.smsCode" placeholder="验证码" size="large">
          <a-icon slot="prefix" type="code" style="color: rgba(0, 0, 0, 0.25)" />
        </a-input>
        <!-- 发送验证码按钮 -->
        <div class="send-smsCode-btn">
          <a-button @click="sendSmsCode" v-if="isSend">获取验证码</a-button>
          <a-button v-if="!isSend" disabled>{{ count }}s后重新获取</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="next" class="submit">下一步</a-button>
      </a-form-model-item>
    </a-form-model>
    <div class="login-back d-f j-c">
      <a @click="goLogin">返回登录</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      checkAgree: false,
      form: {
        phone: "",
        smsCode: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入你的手机号!",
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "不是正确的手机格式!",
          },
        ],
        smsCode: [
          {
            required: true,
            message: "请输入验证码!",
          },
          {
            pattern: /^\d{4}$/,
            message: "验证码格式不正确!",
          },
        ],
      },
      count: 60,
      isSend: true,
      timer: null,
    };
  },
  methods: {
    //下一步
    next() {
      this.$refs.ruleConfirmForm.validate((valid) => {
        if (valid) {
          this.$parent.current = "reset";
          this.$parent.cannotReset = false;
          this.$store.state.forget.phone = this.form.phone;
          this.$store.state.forget.smsCode = this.form.smsCode;
          console.log(this.$store.state.forget);
          //验证手机号和验证码是否匹配
          //   this.$api
          //     .verifyCode({
          //       phone: this.form.mobile,
          //       smsCode: this.form.smsCode,
          //       smsCodeType: 2,
          //     })
          //     .then((res) => {
          //       if (res.success) {
          //         this.$store.state.phone = this.form.mobile;
          //         // this.$parent.showFromSgin();
          //       } else {
          //         this.$message.error(res.message);
          //       }
          //     });
        }
      });
    },
    //发送验证码
    sendSmsCode() {
      this.$refs.ruleConfirmForm.validateField("phone", (err) => {
        if (!err) {
          console.log(111);
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //验证手机号已经注册过
            // this.$api.isExist({ phone: this.form.phone}).then((res) => {
            //   // console.log(res)
            //   if (res.success) {
            //     //发送短信验证码
            //     this.$api
            //       .sendSmsCode({
            //         phone: this.form.phone,
            //         smsCodeType: 2,
            //       })
            //       .then((res) => {
            //         // console.log(res)
            //         this.$message.info(res.message);
            //         return;
            //       });
            //   } else {
            //     this.$message.error("该手机号还没被注册过");
            //   }
            // });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    //返回登录
    goLogin() {
      this.$router.push("/login");
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.form-model {
  margin-top: 40px;
  width: 100%;
  ::v-deep .ant-input-affix-wrapper {
    .ant-input {
      padding-left: 40px;
      border: none;
      border-bottom: 1px solid #e8eaee;
    }
  }
  .send-smsCode {
    position: relative;
    .send-smsCode-btn {
      position: absolute;
      right: -140px;
      top: -10px;
    }
  }
  .handle-psw {
    width: 100%;
    margin-bottom: 10px;
  }
  .submit {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
}
</style>
